<template>
  <div style="color: #000" class="box my_warp">
    <h1 style="text-align: center; font-size: 17px; padding: 15px 0">
      用户举报和投诉处理制度
    </h1>
    第一条<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}（以下简称“公司”）为了加强对网络信息的安全保护，根据《中华人民共和国信息安全法》、《中华人民共和国个人信息保护法》及其他有关法律、行政法规的规定，制定本制度。<br />
    第二条<br />
    &emsp;&emsp;举报投诉的受理和回复工作统一由应用事业部设专人负责，公司运行的所有APP，应在用户协议及隐私政策中向用户公开投诉举报方式，并在APP中设置专门的投诉举报入口。<br />
    第三条<br />
    &emsp;&emsp;受理的有害信息投诉事件主要指单位和个人利用公司运营的APP、网站、自媒体账号等可发布信息的平台，制作、复制、查阅和传播下列信息的事件：<br />
    &emsp;&emsp;一、煽动抗拒、破坏宪法和法律、行政法规实施的；<br />
    &emsp;&emsp;二、煽动颠覆国家政权、推翻社会主义制度的；<br />
    &emsp;&emsp;三、煽动分裂国家、破坏国家统一的；<br />
    &emsp;&emsp;四、煽动民族仇恨、民族岐视，破坏民族团结的；<br />
    &emsp;&emsp;五、捏造或者歪曲事实，散布谣言，扰乱社会秩序的；<br />
    &emsp;&emsp;六、宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖，教唆犯罪的；<br />
    &emsp;&emsp;七、公然侮辱他人或者捏造事实诽谤他人的； 八、损害网站形象和网站利益的；<br />
    &emsp;&emsp;九、其他违反宪法和法律、行政法规的。 第三条<br />
    &emsp;&emsp;公司对公众举报、投诉事件，按集中管理、登记的原则办理，由应用事业部带领网络信息安全小组集中处理，并对处理结果备案。对较重大有害信息事件，应立即上报主管领导。<br />
    第四条<br />
    &emsp;&emsp;客服专员受理的事件，要做到即接快办；夜间、节假日值班期间接到的投诉举报，应于次日或节假日后的第一个工作日办理，对需紧急办理的重大信息安全事件可先处理后登记。<br />
    第五条<br />
    &emsp;&emsp;负责查办的客服专员接到交办的投诉举报事件后应及时安排办理，要求在法定时限内处理完毕，如遇特殊性情况不能按时处理完毕的，应报主管领导说明理由，可适当延长处理时间；项目组对处理结果应及时反馈给客服专员，由客服专员反馈绘投诉举报人。<br />
    &emsp;&emsp;在处理有害信息投诉事件的记录、登记、交办工作流程时，应填写相应表单、并随结果报告一同存档。<br />
    第六条<br />
    &emsp;&emsp;客服专员应对重大有害信息事件举报人或要求保密者做到保密，有关重大的有害信息事件及处理过程不得泄密。
  </div>
</template>

<script>
export default {
  data(){
    return{
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
    }
  }
};
</script>

<style lang="less" scoped>
.box{
  font-size: 12px;
}
</style>